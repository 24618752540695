<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <template slot="list" slot-scope="data">
      <b-table
        id="report"
        class="edge"
        :fields="fields"
        :items="rows"
        :filter="filter"
        :sort-by="data.sort.by"
        :sort-desc="data.sort.desc"
        @sort-changed="$emit('sortChange', $event)"
        no-local-sorting
        responsive
      >
        <template slot="name" slot-scope="data">
          <expander
            :level="data.item.level"
            :has-items="hasItems(data.item)"
            :expanded="data.item._expanded"
            @expand="expand(data.item)"
          >
            <span slot="text" @click="expand(data.item)">
              <responsible v-if="data.item.user" :responsible="data.item.user" class="mr-5"></responsible>

              <component :is="groupKeys.indexOf(data.item.id) !== -1 || !data.item.parent ? 'b' : 'span'">
                {{ data.value }}
              </component>
            </span>
          </expander>
        </template>

        <template slot="total" slot-scope="data">
          <template v-if="data.value">
            {{ data.value.value | formatNumber }}
            <template v-if="['closedHours', 'hours', 'hoursInside', 'hoursProjects'].indexOf(data.item.id) !== -1">
              ч
            </template>
            <template v-else> &#8381; </template>
            <template v-if="groupKeys.indexOf(data.item.id) === -1">
              ({{ data.value.percent | formatNumber }}%)
            </template>
          </template>
          <template v-else> - </template>
        </template>

        <template v-for="month in months" :slot="month.key" slot-scope="data">
          <template v-if="$dotGet(data, ['item', 'dates', month.key])">
            <b-link :to="generateLink(data.item, month.key)" target="_blank" style="font-weight: 400 !important">
              {{ data.item.dates[month.key].value | formatNumber }}
              <template v-if="['closedHours', 'hours', 'hoursInside', 'hoursProjects'].indexOf(data.item.id) !== -1">
                ч
              </template>
              <template v-else> &#8381; </template>
              <template v-if="[...groupKeys, 'hoursCost', 'hoursMargin', 'hoursWorth'].indexOf(data.item.id) === -1">
                ({{ data.item.dates[month.key].percent | formatNumber }}%)
              </template>
            </b-link>
          </template>
        </template>
      </b-table>
    </template>
  </list>
</template>

<script>
  import api from '../../../assets/js/api'
  import forEach from 'lodash/forEach'
  import cloneDeep from 'lodash/cloneDeep'
  import qs from 'qs'
  import { getUsersDropdown } from '../../../assets/js/common'

  import entityMixins from '../../../mixins/entity'
  import stairsMixins from '../../../mixins/stairs'

  import List from '../../../components/List'
  import Expander from '../../../components/Expander'
  import Responsible from '../../../components/Responsible'

  export default {
    name: 'Report',
    components: {
      List,
      Responsible,
      Expander,
    },
    mixins: [entityMixins, stairsMixins],
    data() {
      return {
        filters: [
          {
            name: 'date',
            type: 'date',
            range: true,
            legend: 'Период',
          },
          {
            name: 'users',
            label: 'Отделы',
            type: 'select',
            items: [],
            width: 2,
            multiple: true,
          },
          {
            label: 'Тип сделки',
            name: 'lead_type_id',
            type: 'select',
            items: [],
            width: 2,
            multiple: true,
          },
        ],
        rootKey: 'data',
        groupKeys: ['budget', 'closedHours', 'hours', 'worth'],
      }
    },
    computed: {
      months() {
        // Месяца
        let filters = this.entity.originalResult._filters

        let from = moment(filters['date.from'])
        let to = moment(filters['date.to'])

        let months = []
        for (let date = from; date <= to.endOf('month'); date.add(1, 'm')) {
          months.push({
            label: date.format('MMMM YY'),
            key: date.format('YYYY-MM'),
            class: 'first-upper text-center',
          })
        }

        return months
      },
      fields() {
        let fixed = [
          {
            label: 'Отдел/группа',
            key: 'name',
          },
          {
            label: 'Итого',
            key: 'total',
            class: 'text-center',
          },
        ]

        return [...fixed, ...this.months]
      },
    },
    methods: {
      resultHandler(result) {
        let items = []

        result.data.forEach(item => {
          if (item.id === 'all') {
            item._expanded = true
          }

          item._rowVariant = 'bold'

          let subItems = []
          forEach(item.items, subItem => {
            if (this.groupKeys.indexOf(subItem.id) !== -1) {
              subItem._rowVariant = 'bold'
              subItems.push({ _rowVariant: 'delimiter mini-delimiter' })
            }

            subItems.push(subItem)
          })

          item.items = subItems

          items.push(item)
          // Добавляем разделитель
          items.push({ _rowVariant: 'delimiter' })
        })

        result.items = items

        this.dataPrepare(result)
      },

      generateLink(item, month) {
        let path,
          params = {}

        let projectMovementsLink = [
          'budget',
          'closedWorth',
          'closedOperations',
          'closedMargin',
          'closedHours',
          'hoursCost',
          'hoursMargin',
          'hoursWorth',
        ]

        let salaryLinks = ['hours', 'hoursInside', 'hoursProjects', 'worth', 'worthInside', 'worthProjects']

        if (projectMovementsLink.indexOf(item.id) !== -1) {
          path = 'reports/projects_movement'
        } else if (salaryLinks.indexOf(item.id) !== -1) {
          path = 'reports/salary'
        }

        if (!path) return null

        if (month) {
          let date = moment(`${month}-01`)

          Object.assign(params, {
            date: {
              from: date.format('YYYY-MM-DD'),
              to: date.endOf('month').format('YYYY-MM-DD'),
            },
          })
        }

        let departmentId = this.$dotGet(item, ['parent', 'id'], null)

        if (departmentId && departmentId !== 'all') {
          params.manager_id = `department_${departmentId}`
        }

        let link = `/${path}?${qs.stringify({
          filters: { [path]: params },
        })}`

        return link
      },
    },
    beforeRouteEnter(to, from, next) {
      Promise.all([
        api.base.get({
          path: 'fields/2',
        }),
        getUsersDropdown({ users: false }),
      ]).then(([fields, departments]) => {
        next(vm => {
          let filters = vm.filters

          fields.fields.forEach(field => {
            if (field.alias === 'lead_type') {
              filters.find(filter => {
                return filter.name === 'lead_type_id'
              }).items = field.enums.map(fieldEnum => {
                return {
                  value: fieldEnum.id,
                  name: fieldEnum.value,
                }
              })
            }
          })

          filters
            .filter(filter => {
              return ['users'].indexOf(filter.name) !== -1
            })
            .forEach(f => (f.items = cloneDeep(departments)))
        })
      })
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    th.first-upper {
      text-transform: capitalize;
    }

    .table-bold {
      td {
        font-weight: 600;
      }
    }
  }
</style>
